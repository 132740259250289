// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-resume-tsx": () => import("./../src/templates/resume.tsx" /* webpackChunkName: "component---src-templates-resume-tsx" */)
}

